import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

import { AuthService, UserService } from '@app/services';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  errorMessage: string = '';
  loginAvailable: boolean = false;

  isJumpRoute: boolean = false; // Lets us know if jump assets and name need to be displayed.

  constructor(
    private _authService: AuthService,
    private _userService: UserService,
    private router: Router
  ) {
    const urlSource = window.location.href;
    const routeSrc = this.router?.url;
    // console.log('Url Source: ', urlSource);

    // Check if "jumphire" exists in either the full window URL or the Angular route URL.
    this.isJumpRoute = urlSource.includes('jumphire') || routeSrc?.includes('jumphire');
  }

  ngOnInit() {
    this.logout();
  }


  async logout() {
    await lastValueFrom(this._userService.logoutWithoutRemovingUser());

    this._authService.stopUserIdle();

    const hasCurrentUserInStorage = localStorage.getItem('currentUser');

    if (hasCurrentUserInStorage) {
      // console.log('Has User in storage. Removing them and reloading...');
      localStorage.removeItem('currentUser');
      window.location.reload();
    } else {
      // do not want them to navigate away to login before reloading.. Need to get new layouts, etc.
      this.loginAvailable = true;
    }

    this._authService.logout();
  }


  login() {
    this.router.navigate(['/login']);
  }
}