export class TimeSheet {
    _id: string;
    user: any;
    status: string;
    date_submitted: Date;
    generated_on: Date;
    total_hours: number;
    timeEntries: any;
    deleted: boolean;

    name: string; // Used for custom modules
    assigned_to: any; // Used for custom modules
    client: any; // Used for custom modules
    created_by: any; // Used for custom modules
    modified_by: any; // Used for custom modules
    teams: any; // Used for custom modules

    createdDateRange: any; // Used for search
    createdStartDate: any; // Used for search
    createdEndDate: any; // Used for search
    hasSearchTerms: any; // Used for search
}