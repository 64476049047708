import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Observable } from 'rxjs/internal/Observable';

import { AchievementNotificationComponent } from '@app/modules/notifications/achievement-notification/achievement-notification.component';
import { Achievement, AchievementNotificationPopupData } from '@app/models';

export interface AchievementApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class AchievementService {
  private currentAchievement;

  private achievementNotificationsToShow: AchievementNotificationPopupData[] = []; // Used in function to trigger multiple achievement notifications.

  private API_URL = '/api/achievements/';
  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  public timeSpanOptions = [
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: 'Year', value: 'year' },
    { label: 'Indefinite', value: 'indefinite' }
  ];

  public appliesToOptions = [{ label: 'New and Updated Records', value: 'both' }, { label: 'New Record Only', value: 'new' }, { label: 'Updated Records Only', value: 'update' }];

  public rewardTypeOptions = [
    { label: 'Coins', value: 'coins' },
    { label: 'Points', value: 'points' }
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private httpClient: HttpClient,
    public snackBar: MatSnackBar,
  ) { }

  setCurrent(_achievement): void {
    this.currentAchievement = _achievement;
  }


  getCurrent() {
    return this.currentAchievement;
  }


  setNotificationsToShow(_notificationsToShow: AchievementNotificationPopupData[]): void {
    this.achievementNotificationsToShow = _notificationsToShow;
  }


  getNotificationsToShow() {
    return this.achievementNotificationsToShow;
  }


  getAll(): Observable<Achievement[]> {
    return this.httpClient.get<Achievement[]>(this.API_URL);
  }


  get(_id: string): Observable<Achievement> {
    return this.httpClient.get<Achievement>(this.API_URL + _id);
  }


  create(_achievement): Observable<Achievement> {
    return this.httpClient.post<Achievement>(this.API_URL, JSON.stringify(_achievement), this.headerOptions);
  }


  search(searchTerms): Observable<Achievement[]> {
    return this.httpClient.post<Achievement[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  searchWithLimitAndSort(searchTerms, sortField = 'name', sortDirection = 'desc', pageSize: number = 10): Observable<Achievement[]> {
    searchTerms.sortField = sortField;
    searchTerms.sortOrder = sortDirection;
    searchTerms.pageSize = pageSize;

    return this.httpClient.post<Achievement[]>(this.API_URL + 'search-with-limit-and-sort', JSON.stringify(searchTerms), this.headerOptions);
  }


  triggerNewAchievementEmailTest(_currentUser, _achievement): Observable<any> {
    const emailRequest = { currentUser: _currentUser, achievement: _achievement };
    return this.httpClient.post<any>(this.API_URL + 'trigger-new-achievement-email-test', JSON.stringify(emailRequest), this.headerOptions);
  }


  selectAllSearch(searchParams): Observable<AchievementApi> {
    searchParams['selectAll'] = true;
    return this.httpClient.post<AchievementApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<AchievementApi> {
    searchParams['selectAll'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<AchievementApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  update(_achievement: Achievement): Observable<Achievement> {
    return this.httpClient.put<Achievement>(`${this.API_URL}${_achievement._id}`, JSON.stringify(_achievement), this.headerOptions);
  }


  markDeleted(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  markDeletedByIdArray(arrayOfIds): Observable<any[]> {
    const deleteRequest = { ids: arrayOfIds };
    return this.httpClient.put<any>(this.API_URL + 'mark-as-deleted/by-array', JSON.stringify(deleteRequest), this.headerOptions);
  }


  delete(_id: string): Observable<Achievement> {
    return this.httpClient.delete<Achievement>(this.API_URL + _id);
  }


  deleteByIdArray(arrayOfIds): Observable<any[]> {
    const deleteRequest = { ids: arrayOfIds };
    return this.httpClient.put<any>(this.API_URL + 'delete-forever/by-array', JSON.stringify(deleteRequest), this.headerOptions);
  }


  showAchievementNotification(_popupData: AchievementNotificationPopupData) {
    const displayTime: number = 12000; // 12001; // Popup animation takes 10.5s which is 10500 milliseconds

    this.snackBar.openFromComponent(AchievementNotificationComponent, {
      // duration: displayTime,
      duration: undefined, // Let the timeout on achievement-notification close this. Easier than keeping them in sync.
      horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
      panelClass: ['achievement-snackbar'],
      data: {
        title: (_popupData.title != undefined && _popupData.title !== '') ? _popupData.title : 'New Achievement',
        score: (_popupData.score != undefined) ? _popupData.score : 0,
        rare: (_popupData.rare != undefined) ? _popupData.rare : false,
        display_time: displayTime - 1,
        mute_audio: (_popupData.mute_audio != undefined) ? _popupData.mute_audio : false,
        badge_url: _popupData.badge_url
      }
    });
  }


  async showMultiAchievementNotification() {
    const _achievementNotificationsToShow: AchievementNotificationPopupData[] = this.achievementNotificationsToShow;

    if (_achievementNotificationsToShow != undefined && _achievementNotificationsToShow.length) {
      for (let index = 0; index < _achievementNotificationsToShow.length; index++) {
        const _achievementNotification = _achievementNotificationsToShow[index];
        // console.log(_achievementNotification);
        await this.triggerAchievementNotificationAndWait(_achievementNotification);
      }

      this.achievementNotificationsToShow = []; // Reset to empty array so these are not present for the next time this is called.
    }
  }


  triggerAchievementNotificationAndWait(_an: AchievementNotificationPopupData) {
    return new Promise(async (resolve, reject) => {
      const snackBarRef = this.snackBar.openFromComponent(AchievementNotificationComponent, {
        // duration: displayTime,
        duration: undefined, // Let the timeout on achievement-notification close this. Easier than keeping them in sync.
        horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
        panelClass: ['achievement-snackbar'],
        data: {
          title: (_an.title != undefined && _an.title !== '') ? _an.title : 'New Achievement',
          score: (_an.score != undefined) ? _an.score : 0,
          rare: (_an.rare != undefined) ? _an.rare : false,
          display_time: 12000,
          mute_audio: (_an.mute_audio != undefined) ? _an.mute_audio : false,
          badge_url: _an.badge_url
        }
      });

      await lastValueFrom(snackBarRef.afterDismissed());
      resolve(null);
    });
  }


  achievementFormGroup(formData: Achievement) {
    return this.formBuilder.group({
      client: [formData.client, Validators.required],
      name: [formData.name, Validators.required],
      related_module: [formData.related_module, Validators.required],
      appliesTo: [formData.appliesTo, Validators.required],
      teams: [formData.teams, Validators.required],
      badge: [formData.badge],
      description: [formData.description],
      isRare: [formData.isRare],
      active: [formData.active],
      rewards: [formData.rewards]
    });
  }
}