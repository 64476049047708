import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Observable } from 'rxjs/internal/Observable';
import { SharedUtilsService } from '.';

export interface PtoBalanceApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class PtoBalanceService {
  private currentRecord;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/pto-balances/';

  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  public subStatuses = null;

  constructor(
    private httpClient: HttpClient,
    private _sharedService: SharedUtilsService
  ) { }

  setCurrent(_record): void {
    this.currentRecord = _record;
  }

  getCurrent() {
    return this.currentRecord;
  }


  getUsersBalanceForRequest(_requestRecord): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let ptoBalanceForYear = null;

      if (!_requestRecord.ptoBalance_ptoRequests) {
        // If no pto balance is present on the request, go get it
        // console.log('No PTO Balance is set. Get the balance that corresponds to the pto dates year.');
        const ptoDate = (_requestRecord.pto_date) ? _requestRecord.pto_date : null;
        const ptoYear = (ptoDate) ? ptoDate.getFullYear().toString() : null;
        // console.log('PTO Year: ', ptoYear);

        if (ptoYear) {
          const ptoBalances = await lastValueFrom(this.search({ users_ptoBalance: _requestRecord.users_ptoRequests, year: ptoYear }));

          if (ptoBalances && ptoBalances.length) {
            if (ptoBalances.length > 1) this._sharedService.showErrorDialog("This user has multiple PTO Balance records for the same year!");

            const matchingBalance = ptoBalances[0];
            ptoBalanceForYear = matchingBalance;
          }
        }
      } else {
        ptoBalanceForYear = _requestRecord.ptoBalance_ptoRequests;
      }

      resolve(ptoBalanceForYear);
    });
  }


  create(_record): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_record), this.headerOptions);
  }


  // updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
  //   const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
  //   return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields/', JSON.stringify(updateRequest), this.headerOptions);
  // }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
    const updateRequest = { ids: arrayOfIds, fields: fieldsToUpdate };
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<PtoBalanceApi> {
    return this.httpClient.put<PtoBalanceApi>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  update(_record): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _record._id, JSON.stringify(_record), this.headerOptions);
  }


  updateFavorites(_record): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + 'favorite/' + _record._id, JSON.stringify(_record), this.headerOptions);
  }


  dataSourceSearch(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<any[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  getRecord(_id: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + _id);
  }


  getWithRelationships(_moduleOptions): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'with-relationships/' + _moduleOptions.searchTerms._id, JSON.stringify(_moduleOptions), this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  selectAllSearch(searchTerms): Observable<PtoBalanceApi> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<PtoBalanceApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<PtoBalanceApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<PtoBalanceApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }
}