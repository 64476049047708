import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Observable } from 'rxjs/internal/Observable';
import { UserRewardsRecognition } from '@app/models';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class UserRewardsRecognitionService {
  private API_URL = '/api/user-rewards-recognition/';
  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  private currentRewardsAndRecognitionsObj = null;

  constructor(
    private _authService: AuthService,
    private httpClient: HttpClient
  ) { }


  setCurrentUserAchievements(_rewRecObj: UserRewardsRecognition): void {
    this.currentRewardsAndRecognitionsObj = _rewRecObj;
  }


  getCurrentUserRewardsAndRec(_userId: string): Promise<UserRewardsRecognition> {
    return new Promise(async (resolve, reject) => {
      let currentRewardsAndRecognitions = this.currentRewardsAndRecognitionsObj;

      if (!currentRewardsAndRecognitions) {
        let currentUserRewRec = await lastValueFrom(this.getByUserId(_userId)); // This route will create new one if not one found for user

        this.currentRewardsAndRecognitionsObj = currentUserRewRec;
        currentRewardsAndRecognitions = this.currentRewardsAndRecognitionsObj;
      }

      resolve(currentRewardsAndRecognitions);
    });
  }


  get(_id: string): Observable<UserRewardsRecognition> {
    return this.httpClient.get<UserRewardsRecognition>(this.API_URL + _id);
  }


  // This route will create new one if not one found for user
  getByUserId(_id: string): Observable<UserRewardsRecognition> {
    return this.httpClient.get<UserRewardsRecognition>(this.API_URL + 'get-by-user-id/' + _id);
  }


  getAll(): Observable<UserRewardsRecognition[]> {
    return this.httpClient.get<UserRewardsRecognition[]>(this.API_URL);
  }


  create(_userRewardsRecognition): Observable<UserRewardsRecognition> {
    return this.httpClient.post<UserRewardsRecognition>(this.API_URL, JSON.stringify(_userRewardsRecognition), this.headerOptions);
  }


  update(_userRewardsRecognition: UserRewardsRecognition): Observable<UserRewardsRecognition> {
    return this.httpClient.put<UserRewardsRecognition>(`${this.API_URL}/${_userRewardsRecognition._id}`, JSON.stringify(_userRewardsRecognition), this.headerOptions);
  }


  delete(id: string): Observable<UserRewardsRecognition> {
    return this.httpClient.delete<UserRewardsRecognition>(this.API_URL + id);
  }


  deleteByAchievement(achievementId: string): Observable<UserRewardsRecognition> {
    return this.httpClient.delete<UserRewardsRecognition>(this.API_URL + 'by-achievement/' + achievementId);
  }


  search(searchTerms): Observable<UserRewardsRecognition[]> {
    return this.httpClient.post<UserRewardsRecognition[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectAllSearch(searchTerms: UserRewardsRecognition): Observable<UserRewardsRecognition[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<UserRewardsRecognition[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }
}