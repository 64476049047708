import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// import * as moment from 'moment-mini-ts'

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Observable } from 'rxjs/internal/Observable';

import { CalendarEvent, PtoRequest, User } from '@app/models';
import { CalendarGroupService } from './calendar-group.service';
import { UserService } from './user.service';


@Injectable({ providedIn: 'root' })
export class CalendarEventService {
  private currentCalendarEventBeingViewed: CalendarEvent;

  public colors = [
    /*
    {
      key: 'red',
      label: 'Red',
      primary: '#ad2121',
      secondary: '#FAE3E3'
    },
    */
    {
      key: 'orange',
      label: 'Orange',
      primary: '#FF6700',
      secondary: '#FF9045'
    },
    {
      key: 'yellow',
      label: 'Yellow',
      primary: '#e3bc08',
      secondary: '#FDF1BA'
    },
    {
      key: 'green',
      label: 'Green',
      primary: '#3F7C15',
      secondary: '#74B945'
    },
    {
      key: 'blue',
      label: 'Blue',
      primary: '#1e90ff',
      secondary: '#D1E8FF'
    },
    {
      key: 'purple',
      label: 'Purple',
      primary: '#8806CE',
      secondary: '#A849DB'
    },
    {
      key: 'gray',
      label: 'Gray',
      primary: '#757575',
      secondary: '#B3B3B3'
    }
  ];

  private API_URL = '/api/calendar-events/';
  headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(
    private httpClient: HttpClient,
    private _calendarGroupService: CalendarGroupService,
    private _userService: UserService
  ) { }


  setCurrentCalendarEventBeingViewed(_event: CalendarEvent): void {
    this.currentCalendarEventBeingViewed = _event;
  }


  getCurrentCalendarEventBeingViewed(): CalendarEvent {
    return this.currentCalendarEventBeingViewed;
  }


  getColorByKey(colorKey) {
    let districtColor = null;

    this.colors.forEach(_color => {
      if (_color.key === colorKey) {
        districtColor = _color;
        return districtColor;
      }
    });

    return districtColor;
  }


  buildPtoRequestMockEventsForUser(_requestsToBuildEventsFrom: PtoRequest[], _user: User): Promise<CalendarEvent[]> {
    return new Promise(async (resolve) => {
      if (_requestsToBuildEventsFrom == undefined || !_requestsToBuildEventsFrom.length) _requestsToBuildEventsFrom = [];

      const builtCalendarEvents = _requestsToBuildEventsFrom.map(_request => {
        // _e.start = new Date(new Date(_e.start).toLocaleString('en-US', { timeZone: 'America/New_York' }));
        // _e.end = new Date(new Date(_e.end).toLocaleString('en-US', { timeZone: 'America/New_York' }));

        const _newCalendarEvent = new CalendarEvent();
        _newCalendarEvent._id = _request._id;
        _newCalendarEvent.user = _user;
        _newCalendarEvent.client = (_request.client != undefined) ? _request.client : null;
        _newCalendarEvent.title = 'PTO' + (_request.status != undefined ? ' ' + _request.status : '');
        _newCalendarEvent.date_created = _request.createdAt;
        _newCalendarEvent.color = this.getColorByKey('blue');
        _newCalendarEvent.details = (_request.description != undefined && _request.description !== '') ? _request.description : 'Your pto status is ' + _request.status;
        _newCalendarEvent.related_pto_request_id = _request._id;
        _newCalendarEvent.related_record = _request;

        // Setup start time for mock event
        _newCalendarEvent.start = new Date(_request.pto_date);
        // if (_request.start_time != undefined) {
        //   _newCalendarEvent.start = this.setTime(new Date(_request.pto_date), _request.start_time);
        // } else {
        //   _newCalendarEvent.start = new Date(_request.pto_date);
        // }

        // Setup end time for mock event
        if (_request.hours_requested != undefined && _request.hours_requested > 0) {
          // This is how you add hours to hours to a dates time.
          _newCalendarEvent.end = new Date(new Date(_newCalendarEvent.start).setTime(_newCalendarEvent.start.getTime() + (_request.hours_requested * 60 * 60 * 1000)));
        } else {
          _newCalendarEvent.end = new Date(_newCalendarEvent.start);
        }

        // if (!_e.color) _e.color = this._calendarEventService.getColorByKey('blue');
        // if (!_e.title) _e.title = 'No Title';

        return _newCalendarEvent;
      });

      const calendarEvents = [...builtCalendarEvents];
      // console.log('Calendar Events: ', calendarEvents);

      resolve(calendarEvents);
    });
  }


  showStringAsTime(timeString) {
    if (!timeString) {
      return '';
    }

    const timeParts = timeString.split(':');

    let hour = parseInt(timeParts[0]);
    const minutes = timeParts[1];
    let amPm = 'AM';

    if (hour >= 12) {
      amPm = 'PM';
    }

    if (hour > 12) {
      hour -= 12;
    }

    return `${hour}:${minutes} ${amPm}`;
  }


  addRfpDueDateToCalendar(_rfpRecord): Promise<any> {
    return new Promise(async (resolve, reject) => {
      // console.log('Adding placement start dates to calendar.');

      // console.log('Placement: ', _placement);
      // console.log('Account: ', _account);

      const ptoEvent = new CalendarEvent();

      const rfpName = (_rfpRecord && _rfpRecord.name) ? _rfpRecord.name : '';
      ptoEvent.title = `${rfpName} RFP Pipeline Due`;
      ptoEvent.start = new Date(_rfpRecord.due_date);
      ptoEvent.end = new Date(_rfpRecord.due_date);
      ptoEvent.details = `${rfpName} is due today. ${_rfpRecord.description}`;
      ptoEvent.color = this.getColorByKey('blue');
      ptoEvent.public = true;
      ptoEvent.user = _rfpRecord.created_by;

      const calGroups = await lastValueFrom(this._calendarGroupService.search({ title: 'RFP Proposals' }));

      if (calGroups && calGroups.length) {
        const ptoGroup = calGroups[0];
        ptoEvent.calendarGroup = ptoGroup._id;
      }

      await lastValueFrom(this.create(ptoEvent));

      resolve(null);
    });
  }


  addPlacementStartDatesToCalendar(_placement, _account): Promise<any> {
    return new Promise(async (resolve, reject) => {
      // console.log('Adding placement start dates to calendar.');

      // console.log('Placement: ', _placement);
      // console.log('Account: ', _account);

      const ptoEvent = new CalendarEvent();

      const placementName = (_placement && _placement.name) ? _placement.name : '';
      ptoEvent.title = `${placementName} Expected Start`;
      ptoEvent.start = new Date(_placement.expected_start_date);
      ptoEvent.end = new Date(_placement.expected_start_date);
      ptoEvent.details = `${placementName} is expected to start today for ${_account.name}`;
      ptoEvent.color = this.getColorByKey('blue');
      ptoEvent.public = true;
      ptoEvent.user = _placement.created_by;

      // Look at account and assign to the following accordingly

      // Core Start Dates
      // Miami Start Dates
      // Broward Start Dates

      let calendarGroupTitle = 'Core Start Dates';

      if (_account.name === 'MIAMI DADE COUNTY SCHOOL DISTRICT') {
        calendarGroupTitle = 'Miami Start Dates';
      } else if (_account.name === 'BROWARD COUNTY PUBLIC SCHOOLS' || _account.name === 'BROWARD COUNTY PUBLIC SCHOOLS (Therapy)') {
        calendarGroupTitle = 'Broward Start Dates';
      }

      const calGroups = await lastValueFrom(this._calendarGroupService.search({ title: calendarGroupTitle }));

      if (calGroups && calGroups.length) {
        const ptoGroup = calGroups[0];
        ptoEvent.calendarGroup = ptoGroup._id;
      }

      await lastValueFrom(this.create(ptoEvent));

      resolve(null);
    });
  }


  addPtoRequestToCalendar(_ptoRequest: PtoRequest): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const ptoEvent = new CalendarEvent();

      let userToUse = (_ptoRequest.users_ptoRequests != undefined) ? _ptoRequest.users_ptoRequests : null;

      // We need some data from the related user, so get it if field was set and is only the id.
      if (userToUse != undefined && userToUse._id == undefined) {
        userToUse = await lastValueFrom(this._userService.getUser(userToUse));
      }

      // const ptoTitle = (_ptoRequest && _ptoRequest.users_ptoRequests && _ptoRequest.users_ptoRequests.first_name) ? _ptoRequest.users_ptoRequests.first_name + ' ' + _ptoRequest.users_ptoRequests.last_name : '';
      const ptoTitle = (userToUse && userToUse.first_name) ? userToUse.first_name + ' ' + userToUse.last_name : '';
      ptoEvent.title = 'PTO for ' + ptoTitle;

      ptoEvent.start = new Date(_ptoRequest.pto_date);

      // If there are hours_requested we need to add this time to the start date to get the end date. If not the just set to start date.
      if (_ptoRequest.hours_requested != undefined && _ptoRequest.hours_requested > 0) {
        // This is how you add hours to hours to a dates time.
        ptoEvent.end = new Date(new Date(ptoEvent.start).setTime(ptoEvent.start.getTime() + (_ptoRequest.hours_requested * 60 * 60 * 1000)));
      } else {
        ptoEvent.end = new Date(ptoEvent.start);
      }

      ptoEvent.details = _ptoRequest.description;
      ptoEvent.color = this.getColorByKey('blue');
      ptoEvent.public = true;
      ptoEvent.user = userToUse;
      ptoEvent.related_pto_request_id = _ptoRequest._id;

      const calGroups = await lastValueFrom(this._calendarGroupService.search({ title: 'PTO' }));

      if (calGroups && calGroups.length) {
        const ptoGroup = calGroups[0];
        ptoEvent.calendarGroup = ptoGroup._id;
      }

      await lastValueFrom(this.create(ptoEvent));

      resolve(null);
    });
  }


  updateEventForPtoRequest(_existingEvent: CalendarEvent, _updatedPtoRequest: PtoRequest): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let eventToUpdate: CalendarEvent = { ..._existingEvent };

      let userToUse = (_updatedPtoRequest.users_ptoRequests != undefined) ? _updatedPtoRequest.users_ptoRequests : null;

      // If this stuff was not set correctly and there is a user, then fix info here.
      if (userToUse != undefined && (_existingEvent.title == undefined || _existingEvent.title === 'PTO for ')) {
        // If id is not on user we need to get it to set the info below.
        if (userToUse._id == undefined) userToUse = await lastValueFrom(this._userService.getUser(userToUse));

        const ptoTitle = (userToUse.first_name) ? userToUse.first_name + ' ' + userToUse.last_name : '';
        _existingEvent.title = 'PTO for ' + ptoTitle;
      }

      eventToUpdate.start = new Date(_updatedPtoRequest.pto_date);

      // If there are hours_requested we need to add this time to the start date to get the end date. If not the just set to start date.
      if (_updatedPtoRequest.hours_requested != undefined && _updatedPtoRequest.hours_requested > 0) {
        // This is how you add hours to hours to a dates time.
        eventToUpdate.end = new Date(new Date(eventToUpdate.start).setTime(eventToUpdate.start.getTime() + (_updatedPtoRequest.hours_requested * 60 * 60 * 1000)));
      } else {
        eventToUpdate.end = new Date(eventToUpdate.start);
      }

      eventToUpdate.details = _updatedPtoRequest.description;
      eventToUpdate.color = this.getColorByKey('blue');
      eventToUpdate.related_pto_request_id = _updatedPtoRequest._id;

      // Only set user if not already set.
      if (eventToUpdate.user == undefined && userToUse != undefined) {
        eventToUpdate.user = _updatedPtoRequest.users_ptoRequests;
      }

      // Only set group if not already set.
      if (eventToUpdate.calendarGroup == undefined) {
        const calGroups = await lastValueFrom(this._calendarGroupService.search({ title: 'PTO' }));

        if (calGroups && calGroups.length) {
          const ptoGroup = calGroups[0];
          eventToUpdate.calendarGroup = ptoGroup._id;
        }
      }

      await lastValueFrom(this.update(eventToUpdate));

      resolve(null);
    });
  }


  getAll(): Observable<CalendarEvent[]> {
    return this.httpClient.get<CalendarEvent[]>(this.API_URL);
  }


  getDistrictEvents(_districtId: string): Observable<CalendarEvent[]> {
    return this.httpClient.get<CalendarEvent[]>(this.API_URL + 'events-for-district/' + _districtId);
  }


  create(activity: CalendarEvent): Observable<CalendarEvent> {
    return this.httpClient.post<CalendarEvent>(this.API_URL, JSON.stringify(activity), this.headerOptions);
  }


  update(activity: CalendarEvent): Observable<CalendarEvent> {
    return this.httpClient.put<CalendarEvent>(this.API_URL + activity._id, JSON.stringify(activity), this.headerOptions);
  }


  search(searchTerms): Observable<CalendarEvent[]> {
    return this.httpClient.post<CalendarEvent[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
  }


  searchForOne(searchTerms): Observable<CalendarEvent> {
    return this.httpClient.post<CalendarEvent>(this.API_URL + 'search-for-one', JSON.stringify(searchTerms), this.headerOptions);
  }


  deleteEvent(id: String): Observable<any> {
    return this.httpClient.delete(this.API_URL + id);
  }
}